import {useState} from 'react';

const Profile = ()=>{

    return (
        <>
        <div className='profile-container'> 
        <h1>Employment History</h1>
        <input
        placeholder='most recent job title'
        ></input>


        </div>
        
        </>

    )
}

export default Profile;